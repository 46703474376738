
import glodMineImage from "../assets/img/Game 1 The gold mine treasure map.png";
import skylineImage from "../assets/img/Game 2 Manhattan City Skyline.png";
import wordplayImage from "../assets/img/Game 4 word play make the connection.png";
import TheGreatEscapeImage from "../assets/img/Game 5 the great escape.png";
import The_Restaurant from '../assets/img/Game 3 The restauranteur.png'



export const GameStaticData = [
    {

        gamename: "The Gold Mine Treasure Map",
        idGame: 1,
        gamecompleted: 0,
        gameImage: glodMineImage,
        GameLink: "https://censiobeta.in/CensioGMT/?token=",
        gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game1.mp4"
    },
    {
        gamename: "Skyline",
        idGame: 2,
        gamecompleted: 0,
        gameImage: skylineImage,
        GameLink: "https://censiobeta.in/CensioMC/?token=",
        gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game2.mp4"
    },
    {
        gamename: "The Restaurant",
        idGame: 3,
        gamecompleted: 0,
        gameImage: The_Restaurant,
        GameLink: "https://censiobeta.in/TheRestaurant/?token=",
        gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game3.mp4"
    },
    {
        gamename: "Wordplay",
        idGame: 4,
        gamecompleted: 0,
        gameImage: wordplayImage,
        GameLink: "https://censiobeta.in/Censiowordplay/?token=",
        gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game4.mp4"
    },
    {
        gamename: "The Great Escape",
        idGame: 5,
        gamecompleted: 0,
        gameImage: TheGreatEscapeImage,
        GameLink: "https://censiobeta.in/TheRestaurant/?token=",
        gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game5.mp4"
    },

]