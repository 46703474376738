import React from "react";
import { Redirect, Route } from "react-router-dom";
import HeaderSidebar from "../CommonComponent/HeaderSidebar";
const PrivateRoute = (props) => {

  // const [isAuthenticated, setIsAuthenticated] = useState(false)
  // const [isAuthenticating, setIsAuthenticating] = useState(true)
  const { component: Component, userlogin ,...rest } = props;

  // let email = localStorage.getItem("email");
  // let isLogin = localStorage.getItem("isLogin");
  // let isAdmin = localStorage.getItem('isAdmin')
  // if (isAdmin === 'true') {
  //   isAdmin = true
  // }
  // else {
  //   isAdmin = false
  // }
  // const RoutingFunction = () => {
  //   try {
  //     if (email && isLogin) {
  //       setIsAuthenticated(true);
  //     }
  //     else {
  //       throw new Error("Something went wrong");
  //     }
  //   }
  //   catch (error) {
  //     // console.log("Error: ", error);
  //   }
  //   setIsAuthenticating(false);

  // }


  // useEffect(() => {
  //   RoutingFunction()
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []) 
  // if (isAuthenticating) {
  //   return null;
  // }

  let token = sessionStorage.getItem("ssoToken")
  return <>
    {
      !userlogin?.admin && !userlogin?.udc_user && token ?
      <>
          <HeaderSidebar />
          <Route {...rest} render={props => {
            return (
              userlogin &&
              <Component {...props} />

            )
          }} />
      
    </>
    : !userlogin?.admin && userlogin?.udc_user && token ?
    <Redirect to='/UDC/dashboard' />
    : userlogin?.admin && !userlogin?.udc_user && token ?
    <Redirect to='/admin' />
    : <Redirect to={"/"} />
        

    }
  </>
}

export default PrivateRoute;
