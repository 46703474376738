

export const Paths = {
  Dashboard: "/dashboard",
  DashboardUDC: "/UDC/dashboard",
  LogIn: "/login",
  SignUp: "/register",
  ForgotPassword: "/forgot-password",
  QuestListFirst: "/user-question/:id",
  ResetPassword: "/api/v1/users/password",
  MyAssessments: "/my-assessments",
  Certificate: "/certificates",
  LearningProgram: "/learning-programs",
  Messages: "/messages",
  Profile: "/profile",
  Home: "/",
  About: "/about",
  CompleteSignup: "/complete-signup",
  Admin: "/admin",
  GetUserInfo : "/get-user-info",
  UpdateUserProfile : "/updateuserprofile",
  Completed : "/completed",
  Instructions:'/instructions',
  Welcome : "/welcome",
  AdminReporting : "/reporting",
  StudentAssessment:"/web-assessment",
  UdcStudentAssessment:"/udc-web-assessment",
  UdcAssessementCompleted:"/completed",
  udcWelcome:"/udc-welcome"

};
