import { combineReducers } from "redux";
import profileReducer from "./profileReducer";
import questionListReducer from './qestionListReducer'
import ComponentReducer from "./ComponentReducer";
import authReducer from "./AuthReducer";
import AdminReducer from "./AdminReducers";
import sidebarHoverReducer from './sidebarHoverReducer'
import gamesDetailsReducer from './gamesDetailsReducer'




const rootReducer = combineReducers({
    questionListReducer: questionListReducer,
    profileReducer: profileReducer,
    componentReducer: ComponentReducer,
    authReducer: authReducer,   
    AdminReducer : AdminReducer,
    sidebarHoverReducer:sidebarHoverReducer,
    gamesDetailsReducer:gamesDetailsReducer,
    
})
export default rootReducer