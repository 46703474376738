import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Dimmer, Grid, Icon, Loader } from "semantic-ui-react";
import { ShowGameLink } from "../../Actions/componentActions";
import glodMineImage from "../../assets/img/Game 1 The gold mine treasure map.png";
import skylineImage from "../../assets/img/Game 2 Manhattan City Skyline.png";
// import wordplayImage from "../../assets/img/Game 4 word play make the connection.png";
// import TheGreatEscapeImage from "../../assets/img/Game 5 the great escape.png";
import The_Restaurant from '../../assets/img/Game 3 The restauranteur.png'
import './GameScreen.scss'
import GameVideo from "../gameVideo/GameVideo";

const UDCGameScreen = () => {
    const [Games, setGames] = useState([
        { gamename: "The Gold Mine Treasure Map", idGame: 1, gamecompleted: 0, gameImage: glodMineImage, GameLink: "https://censiobeta.in/CensioGMT/?token=", gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game1.mp4" },
        { gamename: "Skyline", idGame: 2, gamecompleted: 0, gameImage: skylineImage, GameLink: "https://censiobeta.in/CensioMC/?token=", gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game2.mp4" },
        { gamename: "The Restaurant", idGame: 3, gamecompleted: 0, gameImage: The_Restaurant, GameLink: "https://censiobeta.in/TheRestaurant/?token=", gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game3.mp4" },
        // { gamename: "Wordplay", idGame: 4, gamecompleted: 0, gameImage: wordplayImage, GameLink: "https://censiobeta.in/Censiowordplay/?token=", gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game4.mp4" },
        // { gamename: "The Great Escape", idGame: 5, gamecompleted: 0, gameImage: TheGreatEscapeImage, GameLink: "https://censiobeta.in/TheRestaurant/?token=", gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game5.mp4" },

    ])

    const [isLoading, setIsLoading] = useState(false)
    const [gameType, setGameType] = useState(false)
    const [ssoToken, setssoToken] = useState('')
    const [gameCompleted, setGameCompleted] = useState([])
    const [gameNotComplete, setGameNotCompleted] = useState([])
    const [gameData, setgameData] = useState([])
    const [Showtabs, setShowtabs] = useState("awaiting")
    const [GameVideoPopUp, setGameVideoPopup] = useState(false)
console.log(GameVideoPopUp , "GameVideoPopUp");
    // const GameVideoLinks =    [
    //     { gamename: "", gameImage: glodMineImage, gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game_1.mp4" },
    //     { gamename: "", gameImage: skylineImage, gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game_2.mp4" },
    //     { gamename: "", gameImage: The_Restaurant, gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game_3.mp4" },
    //     { gamename: "", gameImage: wordplayImage, gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game_4.mp4" },
    //     { gamename: "", gameImage: TheGreatEscapeImage, gameVideoLink: "https://s3.amazonaws.com/censioanalytics.com/assets/img/Game_5.mp4" }

    // ]

    // const showGame = useSelector((state) => state.componentReducer.showgame)
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        if (sessionStorage.getItem('ssoToken')) {
            setssoToken(sessionStorage.getItem('ssoToken'))
        }

        const copyGames = [...Games]
        setIsLoading(true)
        const uid = sessionStorage.getItem("uid")
        // console.log(uid, "uiduiduid");
        if (uid !== "") {
            Promise.all([
                fetch(`https://censiobeta.in/CensioGame4Api/api/GetUserGame1Status?UID=${uid}&IDGAME=1`),
                fetch(`https://censiobeta.in/CensioGame4Api/api/GetUserGame2Status?UID=${uid}&IDGAME=2`),
                fetch(`https://censiobeta.in/CensioGame3Api/api/GetGame3GameCompletion?OrgId=1&GameId=3&UID=${uid}`),
                // fetch(`https://censiobeta.in/CensioGame4Api/api/GetUserGame4Status?UID=${uid}&IDGAME=4`),
                // fetch(`https://censiobeta.in/CensioGame4Api/api/GetUserGame5Status?UID=${uid}&IDGAME=5`),
            ]).then((responses) => {
                return Promise.all(responses.map((response) => response.json()))
            })
                .then((data) => {

                    const completeGame = copyGames.map((games, ind) => {

                        const clonegameObject = { ...games }
                        const clonegameCompleted = [...gameCompleted]
                        const cloneNotgameCompleted = [...gameNotComplete]

                        if ((data[ind].gamecompleted === 0)) {
                            cloneNotgameCompleted.push(data[ind].gamecompleted)
                            setGameNotCompleted(cloneNotgameCompleted)
                        } else {
                            console.log("else runnnn");
                            clonegameCompleted.push(data[ind].gamecompleted)
                            setGameCompleted(clonegameCompleted)
                        }
                        if (games.idGame === data[ind].idGame) {

                            clonegameObject.gamecompleted = data[ind].gamecompleted
                        }
                        setgameData(data)
                        return clonegameObject
                    })

                    setGames(completeGame)
                    // setIsLoading(false)
                })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if ((gameData.length > 0)) {
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameData])

    const handleclick = (type) => {
        setShowtabs(type)
        if (type === "awaiting") {
            setGameType(false)
        } else {
            setGameType(true)
        }

    }

    const handleGame = (game) => {
        dispatch(ShowGameLink(`${game.GameLink}${ssoToken}`))
        history.push({
            pathname: `/games`,
            state: game.gamename
            // visible: visible,
        })
    }
    const handleGameVideo = (video) => {
        dispatch(ShowGameLink(video.gameVideoLink))
        setGameVideoPopup(true)
        // history.push({
        //     pathname: `/Take_a_Tour`,
        //     // visible: visible,
        // })
    }


    return (
        <div className="assesment-test_sec" style={{margin: "0px"}}>
            <div className="assessment-test_inner" style={{ margin: "0px" }}>
                {/* <Header className="test_heading" as="h2">Games Assessment</Header> */}
                <Dimmer active={isLoading} inverted>
                    <Loader size='large' inline='centered' >
                        Loading...
                    </Loader>
                </Dimmer>
                {/* <Divider hidden /> */}
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <div className="game__tab-row">
                                <Button
                                    onClick={() => handleclick("awaiting")}
                                    className={Showtabs === "awaiting" ? "active__game-tab" : "inactive__game-tab"}
                                    color='grey'
                                >
                                    <Icon name="sync" />Awaiting Your Action
                                </Button>
                                <Button
                                    onClick={() => handleclick("completed")}
                                    className={Showtabs === "completed" ? "active__game-tab" : "inactive__game-tab"}
                                    color='grey'
                                >
                                    <Icon name="check circle" />Completed
                                </Button>
                            </div>
                            <Grid>
                                <Grid.Row className="vt-center">
                                    {Showtabs !== "Take a Tour" ? <>
                                        {!isLoading && ((gameCompleted.length > 0 && gameType) || (gameNotComplete.length > 0)) && Games.map((game, ind) => {
                                            return (!gameType ?
                                                game.gamecompleted === 0 ?
                                                    <Grid.Column mobile={8} tablet={8} computer={5} className="vt-center" key={ind}>
                                                        <div className="game__dashboard_box-container">
                                                            <img src={game.gameImage} style={{ width: '100%' }} alt={game.gamename} />
                                                            <Icon onClick={() => handleGame(game)} name="play circle" className="playbutton" />

                                                            <span className="video_view-link" onClick={() => handleGameVideo(game)} >
                                                                <Icon name="video" className="" ></Icon>See Demo
                                                            </span>

                                                        </div>
                                                        {/* <Image src={game.gameImage} className="grey-img-assessment" /> */}
                                                    </Grid.Column>
                                                    : null
                                                : game.gamecompleted === 1 ?
                                                    <Grid.Column mobile={8} tablet={8} computer={5} className="vt-center">
                                                        <div className="game__dashboard_box-container">
                                                            <img src={game.gameImage} style={{ width: '100%' }} alt={game.gamename} />
                                                            <Icon name="check circle" className="playbutton" />
                                                        </div>
                                                    </Grid.Column>
                                                    : null
                                            )
                                        })}
                                        {
                                            ((gameCompleted.length === 0 && gameType) || (gameNotComplete.length === 0 && !gameType)) && !isLoading &&
                                            <Grid.Column mobile={8} tablet={4} computer={16} className="vt-center" style={{ margin: "auto" }}>
                                                <div className="game__dashboard_box-container" style={{ textAlign: "center" }}>
                                                    {/* <img src={glodMineImage} style={{ width: '100%', visibility: "hidden" }} alt={"Game_image"} /> */}
                                                    <p >No Record</p>
                                                </div>
                                            </Grid.Column>
                                        }
                                    </>

                                        : <>
                                            {/* {GameVideoLinks.map((gameVideo, ind) => {
                                            return (
                                                <Grid.Column mobile={8} tablet={8} computer={5} className="vt-center" key={ind}>
                                                    <div className="game__dashboard_box-container">
                                                        <img src={gameVideo.gameImage} style={{ width: '100%' }} alt={"game video"} />
                                                        <Icon onClick={() => handleGameVideo(gameVideo)} name="play circle" className="playbutton" />
                                                    </div>
                                                </Grid.Column>
                                            )
                                        })} */}

                                        </>}
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>

                    <GameVideo GameVideoPopUp={GameVideoPopUp} setGameVideoPopup={setGameVideoPopup} />
                </Grid>
            </div>
        </div>
    )
}
export default UDCGameScreen;