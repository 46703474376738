import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Paths } from "./routePaths";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import Home from "../Home/Home";
import DashBoardUDC from "../DashboardUDC";
// import UDCRoute from "./UDCRoute";
import { useSelector } from "react-redux";
import PrivateCommonRoute from "./PrivateCommonRoute";
const Instructions = lazy(() => import("../Dashboard/Instructions"))
const NewDashboard = lazy(() => import("../Dashboard/newDashboard"))
const AssismentCompleted = lazy(() => import("../Dashboard/AssismentCompleted"))
const Register = lazy(() => import("../Register/Register"))
const Login = lazy(() => import("../Login/Login"))
const ForgotPassword = lazy(() => import("../Login/ForgotPassword"))
const Dashboard = lazy(() => import("../Dashboard/Dashboard"))
const QuestionListFirst = lazy(() => import("../QuestionList/QuestionListFirst"))
const ResetPassword = lazy(() => import("../Login/ResetPassword"))
// const MyAssessments = lazy(() => import("../MyAssessments/MyAssessments"))
const learningPrograms = lazy(() => import("../LearningPrograms/LearningPrograms"))
const Certificate = lazy(() => import("../Certificates/Certificate"))
const Messages = lazy(() => import("../Messages/Messages"))
const About = lazy(() => import("../About/About"))
const UserRepoting = lazy(() => import("../Admin/UserRepoting/UserRepoting"))
const game_dashboard = lazy(() => import("../GameScreen/GameScreen"))

const PrivacyPolicy = lazy(() => import("../PrivacyPolicy/PrivacyPolicy"))
const TermsOfService = lazy(() => import("../TermsOfService/TermsOfService"))
const GetUserInfo = lazy(() => import("../Admin/GetUserInfo/GetUserInfo"))
const UpdateUserProfile = lazy(() => import("../Admin/UpdateUserProfile/UpdateUserProfile"))
const UserGameInfo = lazy(() => import("../Admin/GameInfo/UserGameInfo"))
const AllGames = lazy(() => import("../AllGames/AllGames"))
const AdminDashboard = lazy(() => import("../Admin/AdminDashboard/AdminDashboard"))
// const UsersStatusTable = lazy(()=> import("../Admin/UsersStatus/UsersStatusTable"))
// const GameVideo = lazy(()=>import("../gameVideo/GameVideo.js"))
const Recentuser= lazy(()=> import("../Admin/recentuser/RecentUser"));
const Researchers = lazy(() => import("../Admin/Researchers/Researchers"))

function Routes(props) {
  const { profileReducer } = useSelector(state => state)
  const { profileDetail } = profileReducer


  const isSubmitted = sessionStorage.getItem("assessmentSubmitted")
  const USER_TYPE = sessionStorage.getItem("USER_TYPE")

  // React.useEffect(() => {

  // }, [isSubmitted === "complete" && window.location.href.split("/")[3] === "user-question"]);



  return (
    <Suspense fallback={<div></div>}>
      <Router>
        <Switch>
          <Route path={Paths.LogIn} component={Login} />
          {/* <AdminRoute path={Paths.Admin} component={AdminDashboard} />
          <AdminRoute path={Paths.GetUserInfo} component={GetUserInfo} />
          <AdminRoute path={Paths.UpdateUserProfile} component={UpdateUserProfile} />
          <AdminRoute path="/game-info" component={UserGameInfo} />
          <AdminRoute path="/reporting/:type" component={UserRepoting} />
          <AdminRoute path="/recent_registrations" component={Recentuser} />
          <AdminRoute  path="/researchers" component={Researchers} /> */}
          {/* <AdminRoute path="/status/:statustype" component={UsersStatusTable} /> */}


          <Route exact path={Paths.SignUp} component={Register} />
          <Route exact path={Paths.ForgotPassword} component={ForgotPassword} />
          <Route exact path={Paths.ResetPassword} component={ResetPassword} />
          <Route exact path={Paths.About} component={About} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms" component={TermsOfService} />
          <Route exact path={Paths.Home} component={Home} />

          <Route exact path="/api/v1/users/confirmation" component={Login} />
          {/* <UDCRoute />  */}
          {/* <UDCRoute  /> */}
          {/* <UDCRoute path={Paths.UdcStudentAssessment}  component={NewDashboard} userlogin={profileDetail?.data?.user || false} /> */}
          {/* <UDCRoute path={Paths.Welcome} component={Dashboard} userlogin={profileDetail?.data?.user || false} /> */}
          {/* <UDCRoute path={Paths.QuestListFirst} component={isSubmitted === "complete" ? Dashboard : QuestionListFirst} userlogin={profileDetail?.data?.user || false} /> */}
          {/* <UDCRoute path={Paths.Completed} component={AssismentCompleted} userlogin={profileDetail?.data?.user || false} /> */}
          <PrivateCommonRoute  path={Paths.Instructions} component={Instructions} userlogin={profileDetail?.data?.user || false} />
          <PrivateCommonRoute  path={Paths.DashboardUDC} exact={true}  component={DashBoardUDC} userlogin={profileDetail?.data?.user || false} />
          <PrivateCommonRoute  path={Paths.UdcStudentAssessment}  component={NewDashboard} userlogin={profileDetail?.data?.user || false} />
          <PrivateCommonRoute  path={Paths.Welcome} component={Dashboard} userlogin={profileDetail?.data?.user || false} />
          <PrivateCommonRoute path={Paths.QuestListFirst} component={isSubmitted === "complete" ? Dashboard : QuestionListFirst} userlogin={profileDetail?.data?.user || false} />
          <PrivateCommonRoute path={Paths.Completed} component={AssismentCompleted} userlogin={profileDetail?.data?.user || false} />

          <AdminRoute userlogin={profileDetail?.data?.user || false} path={Paths.Admin} component={AdminDashboard}  />
          <AdminRoute path={Paths.GetUserInfo} component={GetUserInfo} userlogin={profileDetail?.data?.user || false} />
          <AdminRoute path={Paths.UpdateUserProfile} component={UpdateUserProfile} userlogin={profileDetail?.data?.user || false} />
          <AdminRoute path="/game-info" component={UserGameInfo} userlogin={profileDetail?.data?.user || false} />
          <AdminRoute path="/reporting/:type" component={UserRepoting} userlogin={profileDetail?.data?.user || false} />
          <AdminRoute path="/recentuser" component={Recentuser} userlogin={profileDetail?.data?.user || false} />
          <AdminRoute  path="/researchers" component={Researchers} userlogin={profileDetail?.data?.user || false} />
          <AdminRoute path="/recent_registrations" component={Recentuser} userlogin={profileDetail?.data?.user || false} />

          {/* <AdminRoute path="/status/:statustype" component={UsersStatusTable} /> */}


          {/* <Route exact path={Paths.Assessment} component={Assessment} /> */}

          {/* <PrivateRoute path={Paths.Welcome} component={Dashboard} userlogin={profileDetail?.data?.user || false} /> */}
          <PrivateRoute path={Paths.StudentAssessment} exact={true} component={NewDashboard} userlogin={profileDetail?.data?.user || false} />
          {/* <PrivateRoute path={Paths.DashboardUDC} exact={true} component={DashBoardUDC} /> */}
          <PrivateRoute path={Paths.Dashboard} component={game_dashboard} userlogin={profileDetail?.data?.user || false} />
          {/* <PrivateRoute path={Paths.QuestListFirst} component={isSubmitted === "complete" ? Dashboard : QuestionListFirst} userlogin={profileDetail?.data?.user || false} /> */}
          {/* <PrivateRoute path={Paths.MyAssessments} component={MyAssessments} /> */}
          <PrivateRoute path={Paths.LearningProgram} component={learningPrograms} userlogin={profileDetail?.data?.user || false} />
          <PrivateRoute path={Paths.Certificate} component={Certificate} userlogin={profileDetail?.data?.user || false} />
          <PrivateRoute path={Paths.Messages} component={Messages} userlogin={profileDetail?.data?.user || false} />
          <PrivateRoute path={Paths.Completed} component={AssismentCompleted} userlogin={profileDetail?.data?.user || false} />
          {/* <PrivateRoute path={Paths.Instructions} component={Instructions} userlogin={profileDetail?.data?.user || false} /> */}
          <PrivateCommonRoute path={"/games"} component={AllGames} userlogin={profileDetail?.data?.user || false} />
          {/* <PrivateCommonRoute path={"/Take_a_Tour"} component={GameVideo} userlogin={profileDetail?.data?.user || false} /> */}



          <Redirect to={USER_TYPE === "Admin" ? Paths.Admin
            : USER_TYPE === "UDC_USER" ? Paths.DashboardUDC
              : USER_TYPE === "Assessment_user" ? Paths.Dashboard
                : Paths.Home
          }
          />
        </Switch>
      </Router>
    </Suspense>
  );
}
export default (Routes);
