import React, {useState} from "react";
// import "./ContactDetails.css"
import "./ContactDetails.scss"
import { Grid, Image } from 'semantic-ui-react'
import censioLogo from '../../assets//img/censio_logo.png'
// import { showNotification } from "../../Actions/componentActions";
import { showNotification } from "../../Actions/componentActions";
import { useDispatch } from "react-redux";
import { Button, Icon, Modal, Form, TextArea , Segment, Card, Feed } from 'semantic-ui-react'
import axios from "axios";


function ContactDetails() {
    const [contactData, setContactData] = useState({ firstName: "", lastName: "", email: "", message: "" })
    var apiUrl = global.platformURI;

    const textRegx = /^[a-zA-Z ]*$/
    const dispatch = useDispatch()
    const handleSubmitData = (e) => {
        e.preventDefault();
        console.log("data:working..")

        axios.get(apiUrl + `/api/v1/contact_us`, {
            params: {
                'contact[first_name]': contactData.firstName,
                'contact[last_name]': contactData.lastName,
                'contact[email]': contactData.email,
                'contact[message]': contactData.message
            }
        })
            .then(res => {
                console.log("Resoponse:",res)
                if (res.status === 200) {
                    
                
                    dispatch(showNotification(true, "Thanks for contacting us! We will be in touch with you shortly", "success"))

                } else {
                    dispatch(showNotification(true, "Something went wrong!"))
                 

                }
                setContactData({ firstName: "", lastName: "", email: "", message: "" })
            }).catch(error=>{
                console.log("error:",error)
            })


    }

    const handleChange = (e) => {
        setContactData({
            ...contactData,
            [e.target.name]: e.target.value,
        })

    }

    console.log("contactData:", contactData)
    return (
        <div>
            <section id="contact-form" class="chat-form">
                <div class="container" >
                <div className='modal-padding'>
                    <Grid columns='equal' >
                        <Grid.Row>
                            <Grid.Column>
                                <div class="form" >
                                <Form onSubmit={(e) => { handleSubmitData(e) }} >
                                 
                                        <div className='section-padding'>
                                        <p class="above-title-text" >
                                        Not sure where to start?
                                    </p>
                                    
                                    <h2>Let's get a free Discovery Call</h2>
                                            <Form.Group widths='equal'>
                                                <Form.Input fluid
                                                    required={true} placeholder='First name'
                                                    name='firstName'
                                                    value={contactData.firstName}
                                                    onChange={(e) => { handleChange(e) }}
                                                />
                                                <Form.Input fluid
                                                    name='lastName'
                                                    required={true} placeholder='Last name'
                                                    value={contactData.lastName}
                                                    onChange={(e) => { handleChange(e) }}

                                                />
                                            </Form.Group>
                                            <Form.Input fluid
                                                value={contactData.email}
                                                onChange={(e) => { handleChange(e) }}
                                                name='email'
                                                type='email' required={true} placeholder='Email' />

                                            <Form.Field
                                                id='form-textarea-control-opinion'
                                                control={TextArea}
                                                name='message'
                                                value={contactData.message}
                                                onChange={(e) => { handleChange(e) }}
                                                required={true}
                                                // label='message'
                                                placeholder='Type your message here...'
                                            />

                                        </div>
                                    
                               
                                        <div className='text-center'>
                                            <Button  secondary type='submit' >
                                                Submit
                                            </Button>
                                        </div>
                                 
                                </Form   >
                              
                            </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
               

                </div>
            </section>
        </div>
    )
}

export default ContactDetails;